import React from "react";
import { PageLayout, Typography } from "@components";
import * as styles from "./index.module.scss";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import Input from "@components/Input/Input";
import Select from "@components/Select/Select";
import { Submitbutton } from "@components/Button_new/Button";
import TextArea from "@components/TextArea/TextArea";
import { BlueSquares1, BlueSquares3 } from "@assets/Background";
import { useHubspotForm } from "@helpers/Hooks";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";

const CONTACT_US_SUBMISSION_ROUTE = `https://api.hsforms.com/submissions/v3/integration/submit/19551518/3326fd9a-51f1-4da7-9e30-bad4d8c34063`;

export const SUBJECT_LIST = Object.freeze([
	"Account related",
	"Billing",
	"Feature request",
	"Troubleshoot",
	"Other",
] as const);

export default function ContactUsPage({ location }: any) {
	const { inputProps, handleSubmit, submitStatus } = useHubspotForm(
		{
			email: location?.state?.email || "",
			firstname: "",
			lastname: "",
			subject_2022_april_contact_us: "",
			message: "",
		},
		CONTACT_US_SUBMISSION_ROUTE
	);

	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "contact-us" }}>
			<div style={{ backgroundColor: "var(--light-pink)" }}>
				<BackgroundFadeContainer
					type="blueFade"
					skewBottom
					className={styles.shrinkFade}
					bodyClass={styles.fadeBody}
					fadeHeight={55}
				>
					<BackgroundShapes
						leftShape={{
							shape: <BlueSquares1 />,
							xOffset: -60,
							yOffset: 320,
						}}
						rightShape={{
							shape: <BlueSquares3 />,
							xOffset: 100,
							yOffset: 150,
						}}
					>
						<div className={styles.contactUsContainer}>
							<div className={styles.header}>
								<Typography
									variant="HERO"
									className={styles.title}
								>
									Contact support
								</Typography>
								<Typography variant="SUBTITLE">
									How can we help?
								</Typography>
							</div>
							<div className={styles.contactUsFormContainer}>
								<Input
									label="Work email"
									{...inputProps("email")}
									autoComplete="email"
									emailValidator
									fullWidth
									lightmode
								/>
								<div className={styles.names}>
									<Input
										label="First name"
										className={styles.firstName}
										{...inputProps("firstname")}
										autoComplete="given-name"
										fullWidth
										lightmode
										required
									/>
									<Input
										label="Last name"
										{...inputProps("lastname")}
										autoComplete="family-name"
										fullWidth
										lightmode
										required
									/>
								</div>
								<Select
									label="Subject"
									{...inputProps(
										"subject_2022_april_contact_us"
									)}
									fullWidth
									lightmode
									required
								>
									{SUBJECT_LIST.map((sub, index) => (
										<Select.Option key={sub} value={sub}>
											{sub}
										</Select.Option>
									))}
								</Select>
								<TextArea
									label="How can we help?"
									{...inputProps("message")}
									lightmode
									inputClassName={styles.textArea}
									required
								/>
								<Submitbutton
									submitStatus={submitStatus}
									onClick={handleSubmit}
									theme="light"
								>
									Contact support
								</Submitbutton>
							</div>
						</div>
					</BackgroundShapes>
				</BackgroundFadeContainer>
			</div>
		</PageLayout>
	);
}
