import React, { useEffect, useState } from "react";
import { Typography } from "..";
import * as styles from "../Input/Input.module.scss";
import cn from "classnames";
import { useInputError, ValidationProps } from "@components/Input/Input";
import { Colors } from "@helpers/Colors";

type InputProps = ValidationProps & {
	/** Specific class name to give to the Input  */
	inputClassName?: string;
	/** Input label also be placeholder of the Input */
	label?: string;
	/** Input value */
	value: string;
	/** theme of the input (default=false)*/
	lightmode?: boolean;
	/** Called when the value of the input changes */
	onChange: (value: string) => void;
	/** class name to go on the wrapper */
	className?: string;
	/** A forced error state that displays an error message */
	error?: string;

	inputErrorColor?: Colors;
};

export default function TextArea({
	label,
	value,
	onChange,
	lightmode = false,
	inputClassName,
	className,
	inputErrorColor,
	...validationProps
}: InputProps) {
	const { error, onBlur } = useInputError(value, validationProps);
	return (
		<div
			className={cn(className, styles.genemodInputWrapper, {
				[styles.genemodInputWrapper__lightmode]: lightmode,
			})}
		>
			<div className={styles.genemodInputContainer}>
				<textarea
					className={cn(styles.genemodInput, inputClassName, {
						[styles.genemodInput__error]: !!error,
					})}
					style={{ paddingTop: 24 }}
					value={value}
					onChange={(e) => onChange(e?.target?.value)}
					placeholder={" "}
					onBlur={onBlur}
				/>
				{label && <div className={styles.inputLabel}>{label}</div>}
			</div>
			{error && (
				<Typography
					variant="CAPTION"
					className={styles.inputError}
					color={inputErrorColor}
				>
					{error}
				</Typography>
			)}
		</div>
	);
}
